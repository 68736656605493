.sn-media-slider .slider.bottom-center-paging .slider-pagination, {
  bottom:-20px;
}
.sn-media-slider .slider-pagination .paging-item.flex-active{
  background: #E70D11;
}

.snFooterContainer{
  .sn-social-media-icon{
    background: #E70D11;
    }
  }
